export const environment = {
    production: false,
    errMsg: 'Failed to complete. Please try again.',
    partner: false,
    config: {
        "haystackUrl": "https://silo.dev.service.75f.io/",
        "auth": {
            "caretakerUrl": "https://caretaker.dev.service.75f.io/api/v1"
        },
        "siteSequencerUrl":"https://seq-runner.dev.service.75f.io",
        "notesUrl": "https://notes-api.dev.service.75f.io/",
        "edgeRunnerApi": "https://micropython-edge-api.azurewebsites.net/",
        'devCycleClientId':"dvc_client_a4749bbe_7e11_45e9_9d12_ac5d30d39aea_503e9e6",
    }
};
